<template>
  <div>
    <b-card>
      <b-row class="mb-1">
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="3"
          class="d-flex flex-column"
          style="min-width: 300px"
        >
          <div class="d-flex flex-column justify-content-start">
            <h5 class="mb-0">
              {{ organization.name }}
            </h5>

            <div
              v-if="organization.email"
              class="d-flex align-items-center"
              style="gap: .34rem"
            >
              <a
                :href="`mailto:${organization.email}`"
                class="card-text font-small-2"
              >
                {{ organization.email }}
              </a>

              <b-button
                v-b-tooltip.hover.v-success
                variant="flat-success"
                size="sm"
                class="btn-icon p-25"
                :title="$t('Copy')"
                @click="copy(organization.email)"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </div>
          </div>

          <div class="mt-50">
            <!--  edit btn -->
            <b-button
              size="sm"
              variant="warning"
              @click="$router.push({ name: 'organizationsEdit', params: { 'id': $route.params.id } })"
            >
              <feather-icon
                icon="EditIcon"
                size="14"
              />
              {{ $t("Edit") }}
            </b-button>

            <!-- Delete btn -->
            <b-button
              v-if="checkRequiredPermissions([$roles.ORGANIZATION_DELETE])"
              size="sm"
              variant="outline-danger"
              class="ml-50"
              @click="removeOrganization"
            >
              <feather-icon
                icon="TrashIcon"
                size="14"
              />
              {{ $t("Delete") }}
            </b-button>
          </div>

          <ul
            class="pl-0 mt-50 d-flex flex-column"
            style="row-gap: .34rem"
          >
            <!--    START::vatId    -->
            <li
              class="d-flex flex-wrap flex-md-row align-items-center justify-content-between"
              style="column-gap: 1rem"
            >
              <strong>
                <span class="font-weight-bold">{{ $t('OrganizationDetails.vatId') }}</span>
              </strong>

              <b-alert
                v-if="organization.vatId"
                show
                variant="primary"
                class="py-25 px-50 m-0 font-small-2"
              >
                {{ organization.vatId }}
              </b-alert>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </li>
            <!--    END::vatId    -->

            <!--    START::vatIdType    -->
            <li
              class="d-flex flex-wrap flex-md-row align-items-center justify-content-between"
              style="column-gap: 1rem"
            >
              <strong>
                <span class="font-weight-bold">{{ $t('vatIdType') }}</span>
              </strong>

              <b-alert
                v-if="organization.vatIdType"
                show
                variant="primary"
                class="py-25 px-50 m-0 font-small-2"
              >
                {{ organization.vatIdType }}
              </b-alert>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </li>
            <!--    END::vatIdType    -->

            <!--    START::theValueOfAllProcesses    -->
            <li
              class="d-flex flex-wrap flex-md-row align-items-center justify-content-between"
              style="column-gap: 1rem"
            >
              <strong>
                <span class="font-weight-bold">{{ $t('theValueOfAllProcesses') }}</span>
              </strong>
              <b-alert
                v-if="organization.valueOfAllThread"
                show
                variant="primary"
                class="py-25 px-50 m-0 font-small-2"
              >
                {{ (organization.valueOfAllThread || 0) | priceFormat }} {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
              </b-alert>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </li>
            <!--    END::theValueOfAllProcesses    -->
          </ul>
        </b-col>

        <b-col
          cols="12"
          xl="8"
          sm="12"
        >
          <organization-info :organization="organization" />
        </b-col>

        <b-col v-if="organization.note"
               sm="12"
        >
          <b-alert
            show
            variant="primary"
          >
            <h4 class="alert-heading">
              {{ $t('Note') }}
            </h4>
            <div class="alert-body">
              <div v-html="(organization.note || '').replaceAll('\n', '<br>')" />
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <organization-nav-bar
        :organization="organization"
        :processes="allProcesses"
        :main-thread-id="organization.mainThreadId"
      />

      <app-collapse class="mt-1">
        <app-collapse-item :title="$t('PrimaryAddress')">
          <b-form-group>
            <label>{{ $t('Country') }}</label>
            <b-form-input
              v-model="organization.addressCountry"
              disabled
            />
          </b-form-group>

          <b-row>
            <b-col sm="8">
              <!-- Main Address - City -->
              <b-form-group>
                <label>{{ $t('City') }}</label>
                <b-form-input
                  v-model="organization.addressCity"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <!-- Main Address - Postal code -->
              <b-form-group>
                <label>{{ $t('PostalCode') }}</label>
                <b-form-input
                  v-model="organization.addressPostalCode"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="7">
              <!-- Main Address - Street -->
              <b-form-group>
                <label>{{ $t('Street') }}</label>
                <b-form-input
                  v-model="organization.addressStreet"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col sm="5">
              <!-- Main Address - Building no -->
              <b-form-group>
                <label>{{ $t('BuildingNumber') }}</label>
                <b-form-input
                  v-model="organization.addressNumber"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </app-collapse-item>

        <app-collapse-item :title="$t('AddressForCorrespondence')">
          <b-form-group>
            <label>{{ $t('Country') }}</label>
            <b-form-input
              v-model="organization.addressCorrespondenceCountry"
              disabled
            />
          </b-form-group>

          <b-row>
            <b-col sm="8">
              <!-- Correspondence Address - City -->
              <b-form-group>
                <label>{{ $t('City') }}</label>
                <b-form-input
                  v-model="organization.addressCorrespondenceCity"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <!-- Correspondence Address - Postal code -->
              <b-form-group>
                <label>{{ $t('PostalCode') }}</label>
                <b-form-input
                  v-model="organization.addressCorrespondencePostalCode"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="7">
              <!-- Correspondence Address - Street -->
              <b-form-group>
                <label>{{ $t('Street') }}</label>
                <b-form-input
                  v-model="organization.addressCorrespondenceStreet"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col sm="5">
              <!-- Correspondence Address - Building no -->
              <b-form-group>
                <label>{{ $t('BuildingNumber') }}</label>
                <b-form-input
                  v-model="organization.addressCorrespondenceNumber"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </app-collapse-item>

        <app-collapse-item :title="$t('OrganizationBranches')">
          <b-table
            :items="organization.organizationBranches"
            :fields="deliveryFields"
            striped
            show-empty
            responsive
            style="min-height: unset !important;"
          >
            <!--    Table Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>

            <template #empty>
              <div class="text-center py-1 text-primary font-weight-bold">
                <feather-icon
                  size="17"
                  icon="XCircleIcon"
                />
                {{ $t('NoData') }}
              </div>
            </template>
          </b-table>
        </app-collapse-item>

        <app-collapse-item :title="$t('OpenHours')">
          <b-row>
            <b-col v-for="day in openHoursDays"
                   :key="`open_hour_day_${day}`"
                   sm="12"
                   md="6"
                   lg="3"
                   class="p-25"
            >
              <div class="d-flex justify-content-between pr-1">
                <div>
                  {{ $t(`days.${day}`) }}
                </div>
                <div v-if="organization.openHours && organization.openHours[day]">
                  {{ organization.openHours[day] === 'CLOSED' ? $t('Closed') : organization.openHours[day] }}
                </div>
                <feather-icon
                  v-else
                  icon="MinusIcon"
                />
              </div>
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <!--    START::Details    -->
    <b-card>
      <b-tabs>
        <!--    START::Contacts    -->
        <b-tab :title="$t('Contacts')">
          <b-input-group
            class="d-flex flex-wrap flex-column flex-md-row align-items-md-center"
            style="column-gap: .5rem"
          >
            <b-button
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
              :title="$t('AddContact')"
              size="sm"
              class="mt-n50 mt-md-50"
              @click="showContactOrganizationModal = true"
            >
              <feather-icon icon="PlusIcon" />

              {{ $t('AddContact') }}
            </b-button>
          </b-input-group>

          <b-table
            :items="organization.contacts"
            :fields="contactListFields"
            striped
            responsive
            :busy="isLoading"
            show-empty
            class="mt-1 border rounded-lg"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>

            <!--    Contact    -->
            <template #cell(contact)="{ item }">
              <b-link
                :class="{ 'deleted-text': item.deletedAt }"
                :href="`/contact/${item.id}`"
                target="_blank"
              >
                {{ `${item.firstName} ${item.lastName}` }}
              </b-link>
            </template>

            <template #cell(contactThreads)="{ value, item }">
              <b-button
                v-if="!item.threadsLoading"
                size="sm"
                :disabled="!value.length"
                variant="outline-primary"
                @click="contactModalPreview = item"
              >
                {{ $t('Threads') }}: {{ value.length }}
              </b-button>
              <b-spinner
                v-else
                small
                variant="primary"
              />
            </template>

            <template #cell(position)="{ value }">
              <span v-if="value">
                {{ $t(contactPositionTypeByValue(value)) }}
              </span>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <!--    Action    -->
            <template #cell(action)="{ index, item }">
              <b-button
                v-b-tooltip.hover.v-danger
                size="sm"
                variant="light-danger"
                class="btn-icon p-25"
                :title="$t(`RemoveConnectionOrganization`)"
                :disabled="item.mainOrganizationContact"
                @click="deleteContactFromOrganization(index)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>

            <!--    Other    -->
            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </b-tab>
        <!--    END::Contacts    -->

        <!--    START::Processes    -->
        <b-tab :title="$t('Processes')">
          <b-button
            variant="primary"
            size="sm"
            class="mb-1"
            @click="showProcessModal = true"
          >
            <feather-icon icon="PlusIcon" />

            {{ $t('AddProcess') }}
          </b-button>

          <b-button-group
            class="w-100 mb-1 flex-wrap"
            style="row-gap: .34rem"
          >
            <b-button
              v-for="(process, index) in groupedProcessesByFunnelTypeKeys"
              :key="index"
              v-b-tooltip.hover.v-primary
              :variant="selectedProcess === process ? 'primary' : 'outline-primary'"
              size="lg"
              class="d-flex justify-content-center align-items-center"
              style="column-gap: .5rem"
              :title="process"
              @click="selectedProcessStatus = ''; selectedProcessThread = ''; selectedProcess = process"
            >
              <feather-icon icon="StarIcon" />

              <template v-if="!isMobile">
                {{ process }}
              </template>
            </b-button>
          </b-button-group>

          <b-row>
            <b-col sm="6">
              <sw-select :name="$t('AutomationSection.ContactThreadFunnelTypePoint')">
                <v-select
                  v-model="selectedProcessStatus"
                  :options="selectedProcessesByFunnelType"
                  @input="selectedProcessThread = ''"
                >
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                  <template #option="{ label }">
                    {{ label }}
                  </template>
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                </v-select>
              </sw-select>
            </b-col>
            <b-col sm="6">
              <sw-select :name="$t('Thread')">
                <v-select
                  v-model="selectedProcessThread"
                  :disabled="!selectedProcessStatus"
                  :options="selectedProcessesByFunnelTypePoint"
                >
                  <template #selected-option="{ name }">
                    {{ name }}
                  </template>
                  <template #option="{ name }">
                    {{ name }}
                  </template>
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                </v-select>
              </sw-select>
            </b-col>
          </b-row>

          <b-tabs
            v-if="selectedProcess"
            class="tabs-scroll mt-n25"
            style="row-gap: .5rem"
            nav-class="btn-group btn-group-row-gap-0.5"
            content-class="mt-1"
            pills
            no-nav-style
          >
            <b-tab
              v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_TIMELINE_VIEW_ALL])"
              :title="$t('contactTabs.Timeline')"
              lazy
              :active="activeTab === 'timeline'"
              :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'timeline' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
              :title-link-class="`${activeTab === 'timeline' && 'text-white'}`"
              @click="changeRouter('timeline')"
            >
              <template #title>
                <feather-icon icon="GitPullRequestIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('contactTabs.Timeline') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="GitPullRequestIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('contactTabs.Timeline') }}
                </h4>
              </div>

              <Timeline
                hide-filters
                class="px-md-2"
                is-contact
                :funnel="selectedTypeId()"
                :funnel-point="selectedTypeStatusId()"
                :thread="selectedThreadId()"
                :contacts="allContactsIds"
              />
            </b-tab>

            <b-tab
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL])"
              :title="$t('Emails')"
              lazy
              :active="activeTab === 'emails'"
              :title-item-class="`
                                  btn btn-sm px-50 py-25
                                  ${isMobile && 'btn-icon'}
                                  ${activeTab !== 'emails' ? 'btn-outline-primary' : 'btn-primary'}
                                `"
              :title-link-class="`${activeTab === 'emails' && 'text-white'}`"
              @click="changeRouter('emails')"
            >
              <template #title>
                <feather-icon icon="MailIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('Email') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="MailIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('Email') }}
                </h4>
              </div>

              <Emails
                :show-thread="true"
                :funnel="selectedTypeId()"
                :funnel-point="selectedTypeStatusId()"
                :thread="selectedThreadId()"
                :contacts="allContactsIds"
                class="px-md-2"
              />
            </b-tab>

            <b-tab
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_CHECKLISTS]) && checkRequiredPermissions([
                $roles.CONTACT_THREAD_CHECKLIST_VIEW_ALL,
              ])"
              class="mt-50"
              :title="$t('Checklist')"
              lazy
              :active="activeTab === 'checklist'"
              :title-item-class="`
                                                          btn btn-sm px-50 py-25
                                                          ${isMobile && 'btn-icon'}
                                                          ${activeTab !== 'checklist' ? 'btn-outline-primary' : 'btn-primary'}
                                                        `"
              :title-link-class="`${activeTab === 'checklist' && 'text-white'}`"
              @click="changeRouter('checklist')"
            >
              <template #title>
                <feather-icon icon="CheckSquareIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('contactTabs.Checklist') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('contactTabs.Checklist') }}
                </h4>
              </div>

              <div>
                <Checklist
                  v-for="process in filteredProcesses"
                  :key="`checklist_process_${process.id}`"
                  class="mt-2"
                  :thread="process"
                  :is-visible="false"
                  :contact-id="process.contact.id"
                >
                  <template #title>
                    {{ getUserName(process.contact) }} - {{ process.name }}
                  </template>
                </Checklist>
              </div>
            </b-tab>

            <b-tab
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_TASKS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE])"
              :title="$t('Tasks')"
              lazy
              :active="activeTab === 'tasks'"
              :title-item-class="`
                                  btn btn-sm px-50 py-25
                                  ${isMobile && 'btn-icon'}
                                  ${activeTab !== 'tasks' ? 'btn-outline-primary' : 'btn-primary'}
                                `"
              :title-link-class="`${activeTab === 'tasks' && 'text-white'}`"
              @click="changeRouter('tasks')"
            >
              <template #title>
                <feather-icon icon="ListIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('Tasks') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="ListIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('Tasks') }}
                </h4>
              </div>

              <Tasks
                hide-filters
                :funnel="selectedTypeId()"
                :funnel-point="selectedTypeStatusId()"
                :thread="selectedThreadId()"
                :contacts="allContactsIds"
              />
            </b-tab>

            <!--            <b-tab-->
            <!--              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_DOCUMENTS]) && checkRequiredPermissions([-->
            <!--                $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED,-->
            <!--                $roles.CONTACT_THREAD_TASK_VIEW_ALL,-->
            <!--                $roles.CONTACT_THREAD_NOTE_VIEW_SHARED,-->
            <!--                $roles.CONTACT_THREAD_NOTE_VIEW_SELF,-->
            <!--                $roles.CONTACT_THREAD_NOTE_VIEW_ALL,-->
            <!--                $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED,-->
            <!--                $roles.CONTACT_THREAD_TASK_VIEW_ALL,-->
            <!--                $roles.CONTACT_THREAD_MAIL_VIEW_ALL,-->
            <!--              ])"-->
            <!--              class="mt-50"-->
            <!--              :title="$t('contactTabs.Documents')"-->
            <!--              lazy-->
            <!--              :active="activeTab === 'documents'"-->
            <!--              :title-item-class="`-->
            <!--                                  btn btn-sm px-50 py-25-->
            <!--                                  ${isMobile && 'btn-icon'}-->
            <!--                                  ${activeTab !== 'documents' ? 'btn-outline-primary' : 'btn-primary'}-->
            <!--                                `"-->
            <!--              :title-link-class="`${activeTab === 'documents' && 'text-white'}`"-->
            <!--              @click="changeRouter('documents')"-->
            <!--            >-->
            <!--              <template #title>-->
            <!--                <feather-icon icon="HardDriveIcon" />-->

            <!--                <span class="d-none d-lg-inline-block ml-50">-->
            <!--                  {{ $t('Documents') }}-->
            <!--                </span>-->
            <!--              </template>-->

            <!--              <div-->
            <!--                class="d-flex align-items-center text-primary mb-1"-->
            <!--                style="row-gap: .34rem; column-gap: .5rem;"-->
            <!--              >-->
            <!--                <feather-icon-->
            <!--                  icon="HardDriveIcon"-->
            <!--                  size="18"-->
            <!--                />-->

            <!--                <h4 class="mb-0 text-primary text-capitalize">-->
            <!--                  {{ $t('Documents') }}-->
            <!--                </h4>-->
            <!--              </div>-->

            <!--              <Documents-->
            <!--                class="px-md-2"-->
            <!--                :user-documents="currentUser.documents"-->
            <!--              />-->
            <!--            </b-tab>-->

            <b-tab
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_OFFERS]) && checkRequiredModule('offerModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF])"
              :title="$t('contact.offer')"
              lazy
              :active="activeTab === 'offers'"
              :title-item-class="`
                                  btn btn-sm px-50 py-25
                                  ${isMobile && 'btn-icon'}
                                  ${activeTab !== 'offers' ? 'btn-outline-primary' : 'btn-primary'}
                                `"
              :title-link-class="`${activeTab === 'offers' && 'text-white'}`"
              @click="changeRouter('offers')"
            >
              <template #title>
                <feather-icon icon="CreditCardIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('Offers') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="CreditCardIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('Offers') }}
                </h4>
              </div>

              <Offer
                :thread="selectedThreadId()"
                hide-filters
                :funnel="selectedTypeId()"
                :funnel-point="selectedTypeStatusId()"
                :contacts="allContactsIds"
              />
            </b-tab>

            <b-tab
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_ORDERS]) && checkRequiredModule('orderModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_ORDER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF])"
              :title="$t('contact.offer')"
              lazy
              :active="activeTab === 'orders'"
              :title-item-class="`
                                  btn btn-sm px-50 py-25
                                  ${isMobile && 'btn-icon'}
                                  ${activeTab !== 'orders' ? 'btn-outline-primary' : 'btn-primary'}
                                `"
              :title-link-class="`${activeTab === 'orders' && 'text-white'}`"
              @click="changeRouter('orders')"
            >
              <template #title>
                <feather-icon icon="ShoppingBagIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('Orders') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="ShoppingBagIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('Orders') }}
                </h4>
              </div>

              <Orders
                :thread="selectedThreadId()"
                hide-filters
                :funnel="selectedTypeId()"
                :funnel-point="selectedTypeStatusId()"
                :contacts="allContactsIds"
              />
            </b-tab>

            <b-tab
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_LOYALTY_POINTS]) && checkRequiredModule('loyaltyModule') && checkRequiredPermissions([
                // $roles.USER_LOYALTY_PLAN_VIEW_ALL,
                // $roles.USER_LOYALTY_PLAN_VIEW_SELF,
                // $roles.USER_LOYALTY_PLAN_VIEW_STRUCTURE,
                // $roles.USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST,
                $roles.LOYALTY_PLAN_POINT_VIEW_ALL,
                $roles.LOYALTY_PLAN_POINT_VIEW_SELF,
                $roles.LOYALTY_PLAN_POINT_VIEW_STRUCTURE,
                $roles.LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST,
              ])"
              :title="$t('LoyaltyPoints')"
              lazy
              :active="activeTab === 'loyalty-points'"
              :title-item-class="`
                                  btn btn-sm px-50 py-25
                                  ${isMobile && 'btn-icon'}
                                  ${activeTab !== 'loyalty-points' ? 'btn-outline-primary' : 'btn-primary'}
                                `"
              :title-link-class="`${activeTab === 'loyalty-points' && 'text-white'}`"
              @click="changeRouter('loyalty-points')"
            >
              <template #title>
                <feather-icon icon="StarIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('LoyaltyPoints') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="StarIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('LoyaltyPoints') }}
                </h4>
              </div>
              <points-table
                v-if="checkRequiredPermissions([
                  $roles.LOYALTY_PLAN_POINT_VIEW_ALL,
                  $roles.LOYALTY_PLAN_POINT_VIEW_SELF,
                  $roles.LOYALTY_PLAN_POINT_VIEW_STRUCTURE,
                  $roles.LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST,
                ])"
                ref="points-log-table"
                hide-filters
                :filters="{
                  'thread': selectedThreadId(),
                  'funnel': selectedTypeId(),
                  'funnelPoint': selectedTypeStatusId(),
                  'contacts': allContactsIds,
                }"
              />
            </b-tab>

            <b-tab
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_AGREEMENTS]) && checkRequiredModule('agreementModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF])"
              :title="$t('ListContracts')"
              lazy
              :active="activeTab === 'agreements'"
              :title-item-class="`
                                  btn btn-sm px-50 py-25
                                  ${isMobile && 'btn-icon'}
                                  ${activeTab !== 'agreements' ? 'btn-outline-primary' : 'btn-primary'}
                                `"
              :title-link-class="`${activeTab === 'agreements' && 'text-white'}`"
              @click="changeRouter('agreements')"
            >
              <template #title>
                <feather-icon icon="BookOpenIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('ranks.sections.agreements') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="BookOpenIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('ranks.sections.agreements') }}
                </h4>
              </div>

              <Agreements
                hide-filters
                :thread="selectedThreadId()"
                :funnel="selectedTypeId()"
                :funnel-point="selectedTypeStatusId()"
                :contacts="allContactsIds"
              />
            </b-tab>

            <b-tab
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_POLLS]) && checkRequiredModule('pollModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_POLL_VIEW_ALL])"
              :title="$t('Polls')"
              lazy
              :active="activeTab === 'polls'"
              :title-item-class="`
                                              btn btn-sm px-50 py-25
                                              ${isMobile && 'btn-icon'}
                                              ${activeTab !== 'polls' ? 'btn-outline-primary' : 'btn-primary'}
                                            `"
              :title-link-class="`${activeTab === 'polls' && 'text-white'}`"
              @click="changeRouter('polls')"
            >
              <template #title>
                <feather-icon icon="EditIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('Polls') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="EditIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('Polls') }}
                </h4>
              </div>

              <Polls
                v-for="process in filteredProcesses"
                :key="`poll_process_${process.id}`"
                class="mt-2"
                :thread="process"
              >
                <template #title>
                  {{ getUserName(process.contact) }} - {{ process.name }}
                </template>
              </Polls>
            </b-tab>

            <b-tab
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_PAYMENTS]) && checkRequiredModule('paymentModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF])"
              :title="$t('contactTabs.Payments')"
              lazy
              :active="activeTab === 'payments'"
              :title-item-class="`
                                  btn btn-sm px-50 py-25
                                  ${isMobile && 'btn-icon'}
                                  ${activeTab !== 'payments' ? 'btn-outline-primary' : 'btn-primary'}
                                `"
              :title-link-class="`${activeTab === 'payments' && 'text-white'}`"
              @click="changeRouter('payments')"
            >
              <template #title>
                <feather-icon icon="DollarSignIcon" />

                <span class="d-none d-lg-inline-block ml-50">
                  {{ $t('Schedules') }}
                </span>
              </template>

              <div
                class="d-flex align-items-center text-primary mb-1"
                style="row-gap: .34rem; column-gap: .5rem;"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />

                <h4 class="mb-0 text-primary text-capitalize">
                  {{ $t('Schedules') }}
                </h4>
              </div>

              <Payments
                :allow-filters="false"
                hide-action
                :thread="selectedThreadId()"
                :funnel="selectedTypeId()"
                :funnel-point="selectedTypeStatusId()"
                :contacts="allContactsIds"
              />
            </b-tab>

            <!--            <b-tab-->
            <!--              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_PROJECTS]) && checkRequiredModule('projectModule') && checkRequiredPermissions([$roles.PROJECT_VIEW_ALL])"-->
            <!--              :title="$t('contactTabs.Projects')"-->
            <!--              lazy-->
            <!--              :active="activeTab === 'projects'"-->
            <!--              :title-item-class="`-->
            <!--                      btn btn-sm px-50 py-25-->
            <!--                      ${isMobile && 'btn-icon'}-->
            <!--                      ${activeTab !== 'projects' ? 'btn-outline-primary' : 'btn-primary'}-->
            <!--                    `"-->
            <!--              :title-link-class="`${activeTab === 'projects' && 'text-white'}`"-->
            <!--              @click="changeRouter('projects')"-->
            <!--            >-->
            <!--              <template #title>-->
            <!--                <feather-icon icon="BookmarkIcon" />-->

            <!--                <span class="d-none d-lg-inline-block ml-50">-->
            <!--                  {{ $t('Projects') }}-->
            <!--                </span>-->
            <!--              </template>-->

            <!--              <div-->
            <!--                class="d-flex align-items-center text-primary mb-1"-->
            <!--                style="row-gap: .34rem; column-gap: .5rem;"-->
            <!--              >-->
            <!--                <feather-icon-->
            <!--                  icon="BookmarkIcon"-->
            <!--                  size="18"-->
            <!--                />-->

            <!--                <h4 class="mb-0 text-primary text-capitalize">-->
            <!--                  {{ $t('Projects') }}-->
            <!--                </h4>-->
            <!--              </div>-->

            <!--              <projects-->
            <!--                class="px-md-2"-->
            <!--                :start-filters="{ contactThreads: [selectedThread] }"-->
            <!--                :thread="selectedThread"-->
            <!--                :show-filters="false"-->
            <!--              />-->
            <!--            </b-tab>-->
          </b-tabs>
        </b-tab>
        <!--    END::Processes    -->
      </b-tabs>
    </b-card>
    <!--    END::Details    -->

    <!-- Contact organization modal -->
    <b-modal
      v-model="showContactOrganizationModal"
      :title="$tc('dashboard.tiles.YourAssignedContacts', 2)"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <b-tabs>
        <b-tab :title="$t('SelectContacts')">
          <sw-select :name="$t('AddContactsToOrganization')">
            <v-select
              id="offer-thread-1"
              v-model="selectedContacts"
              :options="contactList"
              label="name"
              :filterable="false"
              :selectable="c => !c.mainOrganizationContact"
              multiple
              @search="loadContacts"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>
                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>

              <template #option="contact">
                <div
                  v-b-tooltip
                  class="d-flex align-items-center"
                  :title="contact.mainOrganizationContact || contact.organization ? (contact.mainOrganizationContact ? 'Nie można przypisać głównego kontaktu organizacji do innej firmy' : 'Kontakt przypisany jest do innej organizacji') : ''"
                >
                  <b-badge
                    v-if="contact.mainOrganizationContact || contact.organization"
                    class="mr-25"
                    variant="primary"
                  >
                    <feather-icon

                      icon="HomeIcon"
                    />
                  </b-badge>
                  <avatar
                    :user="contact"
                    class="mr-25"
                    show-name
                  />
                </div>
              </template>

              <template #selected-option="contact">
                <div
                  v-b-tooltip
                  class="d-flex align-items-center"
                  :title="contact.mainOrganizationContact || contact.organization ? (contact.mainOrganizationContact ? 'Nie można przypisać głównego kontaktu organizacji do innej firmy' : 'Kontakt przypisany jest do innej organizacji') : ''"
                >
                  <b-badge
                    v-if="contact.mainOrganizationContact || contact.organization"
                    class="mr-25"
                    variant="primary"
                  >
                    <feather-icon

                      icon="HomeIcon"
                    />
                  </b-badge>
                  <avatar
                    :user="contact"
                    class="mr-25"
                    text-variant="text-white"
                    show-name
                  />
                </div>
              </template>
            </v-select>
          </sw-select>

          <b-button
            :disabled="!selectedContacts.length"
            size="sm"
            variant="primary"
            @click="(e) => addContactsToOrganization(null)"
          >
            {{ $t('Add') }}
          </b-button>
        </b-tab>

        <b-tab :title="$t('CreateContact')">
          <create-contact
            hide-organization
            is-modal-view
            @close="(e) => addContactsToOrganization(e)"
          />
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      v-model="showProcessModal"
      :title="$tc('AddProcess')"
      hide-footer
      no-close-on-backdrop
    >
      <thread-modal
        :ok-btn="$t('Save')"
        :contact-id="organization.contactId"
        :allowed-contacts="organization.contacts"
        @close="() => { loadOrganizationThreads(); showProcessModal = false }"
      />
    </b-modal>

    <b-modal
      :visible="!!contactModalPreview"
      :title="$tc('Threads') + ' (' + getUserName(contactModalPreview) + ')'"
      hide-footer
      no-close-on-backdrop
      @close="contactModalPreview = null"
    >
      <div v-if="contactModalPreview">

        <b-list-group flush>
          <b-list-group-item
            v-for="process in contactModalPreview.contactThreads"
            :key="`process_preview_${process.id}`"
          >
            <div>
              <router-link
                class="h5"
                :class="{ 'deleted-text': contactModalPreview.deletedAt }"
                :to="`/contact/${contactModalPreview.id}/thread/${process.id}/timeline`"
              >
                {{ process.name }}
              </router-link>
              <avatar-group
                :items="process.assignedUsers"
                size="sm"
              />
              <b-row>
                <b-col md="7">
                  <div
                    v-if="process.contactThreadFunnelTypePoint && process.contactThreadFunnelTypePoint.contactThreadFunnelType"
                    v-b-tooltip
                    :title="$t('AutomationSection.ContactThreadFunnelTypePoint')"
                  >
                    <feather-icon
                      icon="FilterIcon"
                      class="text-primary mr-25"
                    />
                    {{ `${process.contactThreadFunnelTypePoint.contactThreadFunnelType.name} - ${process.contactThreadFunnelTypePoint.name}` | truncate(30) }}
                  </div>
                </b-col>
                <b-col md="5">
                  <div
                    v-b-tooltip
                    :title="$t('ProcessValue')"
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      class="text-primary mr-25"
                    />
                    {{ process.predictedValue | priceFormat }} {{ process.predictedValueCurrency }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-list-group-item>
        </b-list-group>

      </div>
    </b-modal>
    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BButtonGroup, BLink, BTab, BTable, BTabs, VBTooltip,
} from 'bootstrap-vue'
import OrganizationInfo from '@/pages/organizations/components/OrganizationInfo.vue'
import OrganizationNavBar from '@/pages/organizations/components/OrganizationNavBar.vue'
import vSelect from 'vue-select'
import { CONTACTS_SEARCH } from '@/@constants/fields'
import { GET_ORGANIZATION, OPEN_MODAL } from '@/@constants/mutations'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import vatIdTypeList from '@/helpers/vatIdTypeList'
import CreateContact from '@/pages/contact.old/Create.vue'
import axiosIns from '@/libs/axios'
import Orders from '@/pages/orders/List.vue'
import Timeline from '@/pages/GlobalTimeline.vue'
// import Documents from '@/views/core/singleContact/tabs/Documents.vue'
import Offer from '@/pages/offer/List.vue'
import Polls from '@/views/core/singleContact/tabs/Polls.vue'
import Agreements from '@/pages/offer/Agreements.vue'
// import Projects from '@/pages/projects/projects/List.vue'
import Emails from '@/views/core/singleContact/tabs/Emails.vue'
import Tasks from '@/pages/Tasks.vue'
import PointsTable from '@/pages/loyaltyPoints/components/Table.vue'
import Checklist from '@/views/core/singleContact/tabs/Checklist.vue'
import Payments from '@/pages/Schedules.vue'
import { contactPositionTypeByValue } from '@/@types/contactPositionTypes'
import ThreadModal from '@/views/modals/contact/ThreadModal.vue'

export default {
  components: {
    PointsTable,
    Payments,
    Checklist,
    Tasks,
    Emails,
    // Projects,
    Agreements,
    ThreadModal,
    Polls,
    Offer,
    // Documents,
    Timeline,
    Orders,
    CreateContact,
    BButtonGroup,
    BTable,
    vSelect,
    BTab,
    BTabs,
    OrganizationInfo,
    OrganizationNavBar,
    BLink,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    contactModalPreview: null,
    showContactOrganizationModal: false,
    selectedContacts: [],
    allProcesses: [],
    showProcessModal: false,
    selectedProcess: '',
    funnels: [],
    selectedProcessStatus: '',
    selectedProcessThread: '',
    selectedProcessPoint: '',
    activeTab: 'timeline',

    isLoading: false,
    vatIdTypeList,
    organization: {
      contacts: [],
      name: '',
      email: '',
      phone: '',
      assignedUsers: [],
      selectedContacts: [],
      vatId: '',
      vatIdType: '',
      vatPayer: '',
      valueVAT: '',
      theValueOfAllProcesses: '',
      numberOfRelatedContacts: '',
      totalNumberOfThreads: '',
      createdBy: '',
      createdAt: '',
    },
    contactList: [],
    openHoursDays: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY',
    ],
    deliveryFields: [
      { key: 'name', label: 'Name' },
      { key: 'city', label: 'OrganizationDetails.addressCity' },
      { key: 'country', label: 'OrganizationDetails.addressCountry' },
      { key: 'number', label: 'OrganizationDetails.addressNumber' },
      { key: 'postalCode', label: 'OrganizationDetails.addressPostalCode' },
      { key: 'street', label: 'OrganizationDetails.addressStreet' },
    ],
    contactListFields: [
      {
        key: 'contact',
        label: 'Contact',
      },
      {
        key: 'contactThreads',
        label: 'Threads',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'phone',
        label: 'Phone',
      },
      {
        key: 'position',
        label: 'Position',
      },
      {
        key: 'action',
        label: 'Action',
      },
    ],
  }),

  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      singleContact: 'singleContact/getContact',
      contactThreads: 'singleContact/getContactThreads',
      contactStatuses: 'typeSettings/geContactStatuses',
      selectedThread: 'singleContact/getSelectedThread',
      reloadFlag: 'singleContact/getReloadThread',
      currentUser: 'auth/getCurrentUser',
    }),
    groupedProcessesByFunnelType() {
      return this.allProcesses.filter(v => v?.contactThreadFunnelTypePoint).groupBy(v => v?.contactThreadFunnelTypePoint?.contactThreadFunnelType?.name)
    },
    groupedProcessesByFunnelTypeKeys() {
      return Object.keys((this.groupedProcessesByFunnelType || {}))
    },
    groupedProcessesByFunnelTypePoint() {
      return this.allProcesses.groupBy(v => v?.contactThreadFunnelTypePoint?.name)
    },
    selectedProcessesByFunnelType() {
      const type = this.groupedProcessesByFunnelType[this.selectedProcess]
      if (type?.length) {
        return Object.keys(type.groupBy(v => v?.contactThreadFunnelTypePoint?.name))
      }
      return []
    },
    selectedProcessesByFunnelTypePoint() {
      const type = (this.groupedProcessesByFunnelTypePoint[this.selectedProcessStatus] || []).filter(e => e?.contactThreadFunnelTypePoint?.contactThreadFunnelType?.name === this.selectedProcess
            || e?.contactThreadFunnelProcessLast?.contactThreadFunnelTypePoint?.contactThreadFunnelType?.name === this.selectedProcess)
      return type || []
    },
    allContactsIds() {
      return [...this.organization.contacts.mapKeys('id')].filter(Boolean)
    },
    filteredProcesses() {
      const threadId = this.selectedThreadId()
      if (threadId) return this.allProcesses.filter(process => process.id === threadId)

      const statusId = this.selectedTypeStatusId()
      if (statusId) return this.allProcesses.filter(process => process?.contactThreadFunnelTypePoint?.id === statusId)

      const typeId = this.selectedTypeId()
      if (typeId) return this.allProcesses.filter(process => process?.contactThreadFunnelTypePoint?.contactThreadFunnelType?.id === typeId)

      return this.allProcesses
    },
  },

  mounted() {
    this.ini()
  },

  methods: {
    removeOrganization() {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          const { id } = this.$route.params
          axiosIns.delete('1/organizations', { data: JSON.stringify([id]) })
            .then(() => {
              this.$router.push({ name: 'organizations' })
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
    contactPositionTypeByValue,
    selectedTypeId() {
      return this.allProcesses.find(p => p.contactThreadFunnelTypePoint?.contactThreadFunnelType?.name === this.selectedProcess)?.contactThreadFunnelTypePoint?.contactThreadFunnelType?.id || null
    },
    selectedTypeStatusId() {
      return this.allProcesses.find(p => p.contactThreadFunnelTypePoint?.contactThreadFunnelType?.name === this.selectedProcess && p.contactThreadFunnelTypePoint?.name === this.selectedProcessStatus)?.contactThreadFunnelTypePoint?.id || null
    },
    selectedThreadId() {
      return this.selectedProcessThread?.id || null
    },
    async ini() {
      const { tab } = this.$route.params
      if (tab) this.activeTab = tab

      axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
        .then(res => {
          this.funnels = res.data.data.items
        })
      await this.loadContacts()

      await this.loadOrganization()
    },
    changeRouter(tab) {
      this.activeTab = tab
      const { id } = this.$route.params
      this.$router.push(`/organizations/${id}/${tab}`)
    },
    async loadOrganization() {
      try {
        const resp = await this.$store.dispatch(`organizations/${GET_ORGANIZATION}`, this.$route.params.id)

        this.$set(this, 'organization', resp.data.item)

        if (!this.organization.vatIdType) this.organization.vatIdType = ''
        else this.organization.vatIdType = this.vatIdTypeList.find(t => t.value === this.organization.vatIdType).country || ''

        const contactIndex = this.organization.contacts.findIndex(c => c.mainOrganizationContact)
        const mainContact = JSON.parse(JSON.stringify(this.organization.contacts)).splice(contactIndex, 1)

        if (mainContact && contactIndex > -1) {
          const contact = mainContact[0]
          this.organization.contactId = contact.id
          this.organization.email = contact.email
          this.organization.phone = contact.phone
        }

        this.organization.contacts = this.organization.contacts.map(contact => ({ ...contact, contactThreads: [], threadsLoading: true }))

        await this.loadOrganizationThreads()

        return true
      } catch (err) {
        return false
      }
    },

    copy(txt) {
      this.showToast('success', this.$i18n.t('CopiedToClipboard'))

      navigator.clipboard.writeText(txt)
    },

    async loadContacts(search = '', isLoading = false) {
      this.contactList = await this.selectSearch(search, isLoading, CONTACTS_SEARCH, 'firstName,lastName', '1/contacts')
    },

    deleteContactFromOrganization(index) {
      const contact = this.organization.contacts[index]
      if (!contact?.isNew) {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(async () => {
            try {
              await this.saveContact({ id: contact.id, organization: null, isOrganization: false })

              this.$delete(this.organization.contacts, index)
            } catch (err) {
              this.clog(err)
            }
          })
      }
    },
    async addContactsToOrganization(contact) {
      let payload = contact

      if (!contact) {
        payload = this.selectedContacts.map(c => ({ id: c.id, organization: this.$route.params.id, isOrganization: true }))
        if (!payload?.length) return
      } else {
        payload = { id: contact.id, organization: this.$route.params.id, isOrganization: true }
      }

      await this.saveContact(payload)

      this.loadOrganization()

      this.showContactOrganizationModal = false
      this.selectedContacts = []
    },
    async saveContact(payload) {
      try {
        const resp = await axiosIns({
          url: '1/contacts',
          method: payload?.id || Array.isArray(payload) ? 'PATCH' : 'POST',
          data: payload,
        })

        if (resp?.data?.data?.items[0]?.id) {
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          return resp?.data?.data?.items[0]?.id
        }

        return null
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return null
      }
    },
    async loadOrganizationThreads() {
      const assignedContactIds = [...this.organization.contacts.map(c => c.id)].filter(Boolean)

      const params = {
        fields_load: this.$fields.CONTACT_SINGLE_THREAD,
        'eqArr_contact-id': JSON.stringify(assignedContactIds),
      }
      const resp = await axiosIns.get('1/contacts/threads', { params })

      const processes = resp.data?.data?.items.map(process => {
        if (process.contactThreadFunnelProcessSelected) return { ...process, ...process.contactThreadFunnelProcessSelected, id: process.id }
        if (process.contactThreadFunnelProcessLast) return { ...process, ...process.contactThreadFunnelProcessLast, id: process.id }
        return process
      }) || []

      const mainProcess = processes.find(process => process.contact?.id === this.organization.contactId)

      if (mainProcess && mainProcess?.contactThreadFunnelTypePoint?.contactThreadFunnelType?.name) {
        this.organization.mainThreadId = mainProcess.id
        this.selectedProcess = mainProcess.contactThreadFunnelTypePoint?.contactThreadFunnelType.name
      } else if (processes.length) {
        this.selectedProcess = processes[0]?.contactThreadFunnelTypePoint?.contactThreadFunnelType.name
      }

      // Assign process to contact
      this.organization.contacts.forEach((contact, index) => {
        const contactProcesses = processes.filter(process => process?.contact?.id === contact?.id) || []
        this.$set(this.organization.contacts[index], 'contactThreads', contactProcesses)
        this.$set(this.organization.contacts[index], 'threadsLoading', false)
      })

      this.allProcesses = processes
    },

    openModal() {

    },
    openAddProcessModal() {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'thread',
        modalTitle: this.$i18n.t('AddProcess'),
        okButtonTitle: this.$i18n.t('Save'),
        modalClass: 'thread-modal',
        contactId: this.organization.contactId,
      })
    },
  },

  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
}
</script>
